import { css, keyframes } from 'styled-components';

export const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const fadeinKeyFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
`;

export const fadein = css`
  animation: ${fadeinKeyFrames} 100ms cubic-bezier(0.4, 0, 0.2, 1); ;
`;
