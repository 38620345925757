import React, { useContext } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import useFlowManager from 'lib/hooks/useFlowManager';
import { respondTo } from 'styles/helper';
import color from 'styles/colors';
import CheckMark from '../Icons/CheckMark';
import { ACCOUNT_COOKIE_NAME_FOX, ANONYMOUS, COOKIE_CODE_INFO } from 'constants/index';
import { AppContext } from 'context/GlobalContextProvider';

const string = {
  profileSignedIn: "You're signed in!",
  profileSignedUp: 'Your profile has been created!',
  mainTitle: 'Select TV Provider',
};

const Messages = (): JSX.Element | null => {
  const [cookies] = useCookies([ACCOUNT_COOKIE_NAME_FOX, COOKIE_CODE_INFO]);
  const { isMVPD, isNew } = useFlowManager();

  const {
    state: {
      isRegister: { value: isRegister },
    },
  } = useContext(AppContext);

  const mainTitle = <H1>{string.mainTitle}</H1>;

  if (cookies[ACCOUNT_COOKIE_NAME_FOX]?.accountType !== ANONYMOUS || isNew || isRegister) {
    return (
      <>
        <CheckMark />
        <Message>{isRegister ? string.profileSignedUp : string.profileSignedIn}</Message>
        {mainTitle}
      </>
    );
  }

  if (isMVPD || cookies[COOKIE_CODE_INFO]?.isAnonymous) {
    return <>{mainTitle}</>;
  }

  return null;
};

export default Messages;

const Message = styled.p`
  color: ${color.white};
  font-size: 15px;
  font-family: Graphik, serif;
  font-weight: normal;
  letter-spacing: -0.27px;
  line-height: 20px;
  ${respondTo.bp_767`
    font-size: 20px;
    letter-spacing: -0.3px;
  `}
`;

const H1 = styled.h1`
  margin-bottom: 50px;
  font-family: Graphik, serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  ${respondTo.bp_767`
    font-size: 40px;
    letter-spacing: -0.5px;
    line-height: 45px;
    margin: 25px 0 50px;
	`}
`;
