import React from 'react';
import styled, { css } from 'styled-components';
import { rotate } from 'styles/animations';
import loader from 'images/global/loader.png';

type LoaderType = {
  abCenter?: boolean;
};

const Loader = ({ abCenter }: LoaderType): JSX.Element => (
  <StyledLoader abCenter={abCenter}>
    <div role="presentation" className="loader" />
  </StyledLoader>
);

const StyledLoader = styled.div<LoaderType>`
  ${(props) =>
    props.abCenter &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      transform: translate(-50%, -50%);
    `}

  .loader {
    border: none;
    background-image: url(${loader});
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 50%;
    margin: 1rem auto;
    width: 50px;
    height: 50px;
    animation: ${rotate} 0.8s infinite linear;
  }
`;

export default Loader;
