import React, { memo } from 'react';
import NextImage, { ImageLoader } from 'next/image';

type Props = {
  src: StaticImageData | string;
  alt?: string;
  title?: string;
  onClick?: () => void;
  loading?: 'lazy' | 'eager' | undefined;
  className?: string;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive' | undefined;
  width?: number | string;
  height?: number | string;
  loader?: ImageLoader;
  quality?: number | string;
  priority?: boolean;
  lazyBoundary?: string;
  placeholder?: 'blur' | 'empty';
  blurDataURL?: string;
  unoptimized?: boolean;
  objectFit?: 'contain' | 'fill' | 'cover' | 'scale-down';
};

const Image = ({
  src = '',
  width,
  height,
  className,
  alt = '',
  layout,
  loading,
  quality,
  priority,
  title,
  onClick,
  objectFit,
}: Props): JSX.Element => {
  return (
    <NextImage
      src={src}
      width={width}
      height={height}
      className={className}
      alt={alt}
      layout={layout}
      loading={loading}
      quality={quality}
      priority={priority}
      title={title}
      onClick={onClick}
      objectFit={objectFit}
    />
  );
};

export default memo(Image);
