import React from 'react';
import { NextSeo } from 'next-seo';
import { config } from 'lib/utils/configs';

const SEO = ({ title }: { title: string }): JSX.Element => {
  return (
    <NextSeo
      title={title}
      titleTemplate="Second Screen Activation | %s"
      openGraph={{
        type: 'website',
        locale: 'en_IE',
        url: config.registrationUrl,
        site_name: 'Second Screen Activation',
      }}
    />
  );
};

export default SEO;
